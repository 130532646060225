<div class="user-not-active" *ngIf="!isLoading && accountIsActive == 'false'">
    Your account is not activated! Please activate your account in order to access all the features.
</div>
<div class="user-not-active" *ngIf="!isLoading && !apiList.length">
    There are no applications available for your permission level! Please contact the administrator.
</div>
<div class="dashboard">
    <div class="loader" *ngIf="isLoading">
        <mat-spinner color="secondary" diameter="35"></mat-spinner>
        <h1 class="loader-desc">Loading applications...</h1>
    </div>
    <div class="company-card" *ngIf="!isLoading && verifyApiCategory('company')">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Company</mat-card-title>
            </mat-card-header>
            <mat-divider class="section-divider"></mat-divider>
            <mat-card-content>
                <mat-list>
                    <mat-list-item *ngIf="apiStructure['company']['wiki']">
                        <a [attr.href]="getPermittedApiByName('wiki')?.customPath" target="_blank">WIKI Page</a><br />
                    </mat-list-item>
                    <mat-divider *ngIf="apiStructure['company']['wiki']"></mat-divider>
                    <mat-list-item *ngIf="apiStructure['company']['regulations']">
                        <a href="#">Internal Regulations</a><br />
                    </mat-list-item>
                    <mat-divider *ngIf="apiStructure['company']['wiki']"></mat-divider>
                    <mat-list-item *ngIf="apiStructure['company']['events']">
                        <a href="#">Events</a><br />
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="employee-card" *ngIf="!isLoading">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Members</mat-card-title>
            </mat-card-header>
            <mat-divider class="section-divider"></mat-divider>
            <mat-card-content>
                <div *ngIf="!apiStructure['members']['vt']" class="user-not-active">No apps available yet.</div>
                <mat-list>
                    <mat-list-item *ngIf="apiStructure['members']['checkin']">
                        <a href="#">Checkin</a><br />
                    </mat-list-item>
                    <mat-divider *ngIf="apiStructure['members']['checkin']"></mat-divider>
                    <mat-list-item *ngIf="apiStructure['members']['worklogs']">
                        <a [attr.href]="getPermittedApiByName('worklogs')?.customPath" target="_blank"><mat-icon>assignment_turned_in</mat-icon> Worklogs</a>
                        <mat-divider></mat-divider>
                    </mat-list-item>                    
                    <mat-divider *ngIf="apiStructure['members']['activity']"></mat-divider>
                    <mat-list-item *ngIf="apiStructure['members']['career']">
                        <a href="#">Career</a><br />
                    </mat-list-item>
                    <mat-divider *ngIf="apiStructure['members']['career']"></mat-divider>
                    <mat-list-item *ngIf="apiStructure['members']['spaces']">
                        <a href="#">Spaces</a><br />
                    </mat-list-item>
                    <mat-divider *ngIf="apiStructure['members']['spaces']"></mat-divider>
                    <mat-list-item *ngIf="apiStructure['members']['vt']">
                        <a [attr.href]="getPermittedApiByName('vt')?.customPath" target="_blank"><mat-icon>flight_takeoff</mat-icon> Vacations</a><br />
                        <mat-divider></mat-divider>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="settings-card" *ngIf="!isLoading">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Settings</mat-card-title>
            </mat-card-header>
            <mat-divider class="section-divider"></mat-divider>
            <mat-card-content>
                <mat-grid-list cols="3" rowHeight="100px">
                    <mat-grid-tile colspan="1" rowspan="1">
                        <a class="settings-link" href="javascript:void(0);" (click)="openSettings()">
                            <mat-icon>person</mat-icon><br />{{"Settings"}}
                        </a>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <a class="settings-link" href="javascript:void(0);">
                            <mat-icon>alarm</mat-icon><br />{{"Notifications"}}
                        </a>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <a class="settings-link" href="javascript:void(0);">
                            <mat-icon>help_outline</mat-icon><br />{{"Help"}}
                        </a>
                    </mat-grid-tile>
                </mat-grid-list>


            </mat-card-content>
        </mat-card>
    </div>

    <div class="hq-card" *ngIf="!isLoading && verifyApiCategory('hq')">
        <mat-card class="custom-card">
            <mat-card-header>
                <mat-icon>memory</mat-icon>
                <mat-card-title class="mat-card-title-custom"> HQ</mat-card-title>
            </mat-card-header>
            <mat-divider class="section-divider"></mat-divider>
            <mat-card-content>
                <mat-list>
                    <mat-list-item *ngIf="apiStructure['hq']['hr']">
                        <a [attr.href]="getPermittedApiByName('hr')?.customPath" target="_blank">Human
                            Resources</a><br />
                    </mat-list-item>
                    <mat-list-item *ngIf="apiStructure['hq']['pmanager']">
                        <a [attr.href]="getPermittedApiByName('pmanager')?.customPath" target="_blank">Roles &
                            Permissions</a><br />
                    </mat-list-item>
                    <mat-list-item *ngIf="apiStructure['hq']['configurator']">
                        <a [attr.href]="getPermittedApiByName('configurator')?.customPath" target="_blank">Company
                            Configurator</a><br />
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- <div class="hq-card" *ngIf="!isLoading && apiList.length">
        <mat-card class="custom-card">
            <mat-card-header>
                <mat-icon>memory</mat-icon>
                <mat-card-title class="mat-card-title-custom"> HQ</mat-card-title>
            </mat-card-header>
            <mat-divider class="section-divider"></mat-divider>
            <mat-card-content>
                <mat-list>
                    <mat-list-item *ngFor="let item of apiList; let i = index" [attr.data-index]="i">
                        <a [attr.href]="item?.customPath" target="_blank">{{item.name | uppercase }}</a><br />
                        <mat-divider></mat-divider>
                    </mat-list-item>
                    <mat-list-item class="settings-item">
                        <mat-icon>settings</mat-icon>
                        <a href="javascript:void(0);" (click)="openSettings()">{{"Settings" | uppercase }}</a><br />
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div> -->
</div>
