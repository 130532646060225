import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, mergeMap, Observable, Subscription, Subject, of, forkJoin, pipe, switchMap, map, catchError, throwError, tap } from "rxjs";
import { StorageMap } from '@ngx-pwa/local-storage';
import jwtDecode from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(private http: HttpClient, private storage: StorageMap, private jwtHelper: JwtHelperService) { }
    clientToken: any;
    loadClient(clientName: string) {

    }

    isLoaded(): Observable<boolean> {
        return this.storage.get('cliToken').pipe(mergeMap((token: any) => {
            const _isAuthenticated: boolean = (token && !this.jwtHelper.isTokenExpired(token));
            return new BehaviorSubject<boolean>(_isAuthenticated).asObservable();
        }));
    }

    reqCliTokenBff() {
        const urlString = `${environment.APP_BFF_URL}/auth/client`;
        const http$ = this.http.post(urlString, {});

        return http$.pipe(
            catchError(err => {
                console.log('Handling error locally and rethrowing it...', err);
                return of(err);
            }),
            switchMap((response: any) => {
                return of(response);
                // return this.storage.set('cliToken', response.accessToken).pipe(map(() => {
                //     this.clientToken = response.accessToken;
                //     const clientData = jwtDecode(response.accessToken);
                //     return clientData;
                // }));
            })
        );
    }

    setCliTokenInSession(cliToken: any): any {
        return this.storage.set('cliToken', cliToken).pipe(map(() => {
            this.clientToken = cliToken;
            const clientData = jwtDecode(cliToken);
            return clientData;
        }));
    }

    _requestApiListFromBFF(): Observable<any> {
        const headers = new HttpHeaders()
            .set('content-type', 'application/json')
            .append("Authorization", "Bearer " + this.clientToken);

        const urlString = `${environment.APP_BFF_URL}/auth/apis`;
        console.log("requesting ", urlString, headers);
        const http$ = this.http.get(urlString);

        return http$.pipe(
            tap((ev: any) => {
                console.log("tapping ev", ev);
            }),
            catchError(err => {
                console.log('Handling error locally and rethrowing it...', err);
                throw err;
            }),
            switchMap((response: any) => {
                console.log("switchmap", response);
                return this.storage.set('apiList', response).pipe(map(() => {
                    console.log("returining response", response);
                    return response;
                }));
            })
        );
    }

    getCliToken(): Observable<any> {
        return this.storage.get('cliToken').pipe(mergeMap((token: any) => {
            return new BehaviorSubject<any>(token).asObservable();
        }));
    }

    listAllApis(isDecorated?: boolean, appendQuery?: string): Observable<any> {
        return this.storage.has('apiList').pipe(
            switchMap((apiListExists: boolean) => {
                console.log("verific if exists", apiListExists);
                // if (!apiListExists) {
                //     return this._requestApiListFromBFF();
                // }

                return this._requestApiListFromBFF().pipe(map((apiList: any) => {
                    console.log("returining response", apiList);
                    if (isDecorated) {
                        let decoratedApiList = apiList.filter((apiObj: any) => {
                            apiObj.customPath = apiObj.path + appendQuery;
                            return true;
                        });
                        console.log("decoratedApiList", decoratedApiList)
                        return decoratedApiList;
                    } else {
                        return apiList;
                    }

                }));
            })
        );
    }
    //TBD
    listPermittedApisNew(permissions: Array<string> = [], role: string, appendQuery?: string): Observable<any> {
        console.log("listing permissions", permissions);
        //TODO get permissions from local storage
        if (!permissions) {

        }
        //TODO create an API model
        if (role !== "admin") {
            return this.listAllApis().pipe(
                catchError(err => {
                    console.log('Catching error for listingPermittedApis...', err);
                    throw err;
                }),
                map((apiList: any) => {
                    console.log("what is the api list?", apiList);
                    let permittedApiList = apiList.filter((apiObj: any) => {
                        let hasThisApi:boolean = permissions.filter((permission: any) => {
                            return permission.action === 'access' && permission.subject === apiObj.name;
                        }).length > 0;

                        if (hasThisApi) {
                            apiObj.customPath = apiObj.path + appendQuery;
                        }
                        console.log("HAS THIS API", hasThisApi);
                        return hasThisApi;
                    });
                    console.log("what is the permittedApiList?", permittedApiList);
                    return permittedApiList;
                }));
        } else {
            console.log("sunt admin!!!!!!!!, listing all apis with decoration");
            return this.listAllApis(true, appendQuery);
        }

    }
    listPermittedApis(permissions: Array<string> = [], role: string, appendQuery?: string): Observable<any> {
        console.log("listing permissions", permissions);
        //todo: get permissions from local storage
        if (!permissions) {

        }
        //todo create an API model
        // return this.listAllApis();
        if (role !== "admin") {
            return this.listAllApis().pipe(
                catchError(err => {
                    console.log('Catching error for listingPermittedApis...', err);
                    throw err;
                }),
                map((apiList: any) => {
                    console.log("what is the api list?", apiList);
                    let permittedApiList = apiList.filter((apiObj: any) => {
                        const hasThisApi = permissions.includes(`access:${apiObj.name}`);
                        if (hasThisApi) {
                            apiObj.customPath = apiObj.path + appendQuery;
                        }
                        console.log("HAS THIS API", hasThisApi);
                        return hasThisApi;
                    });
                    console.log("what is the permittedApiList?", permittedApiList);
                    return permittedApiList;
                }));
        } else {
            console.log("sunt admin!!!!!!!!");
            return this.listAllApis();
        }

    }

    /* Create Permission Manager API and add permissions to the current client to access */
    createPManagerAPI(): any {
        const data = {
            path: environment.PMANAGER_BASE_URL,
            name: "pmanager"
        };
        const urlString = `${environment.APP_BFF_URL}/auth/api/add`;
        console.log("requesting ", urlString);
        const http$ = this.http.post(urlString, data);

        return http$.pipe(
            tap((ev: any) => {
                console.log("tapping ev", ev);
            }),
            catchError(err => {
                console.log('Handling error locally and rethrowing it...', err);
                throw err;
            }));
    }

    reqCliTokenByAuthCode(authorizationCode: string) {
        const urlString = `${environment.APP_BFF_URL}/auth/client`;
        const http$ = this.http.post(urlString, {
            authorisationCode: authorizationCode
        });
        return http$.pipe(
            catchError(err => {
                console.log('Handling error locally and rethrowing it...', err);
                window.location.href = `${environment.API_LOGIN_CLIENT_REDIRECT_URL}?redirect_uri=${environment.APP_BASE_URL}${environment.APP_DASHBOARD_URL}`;
                throw of(err);
            }),
            switchMap((response: any) => {
                return of(response);
            }));
    }
    logout() {
        this.storage.delete('cliToken').subscribe(() => { });
    }
}
