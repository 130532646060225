import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ClientService } from './client.service';
import { AccountService } from './account.service';
import { Observable, of } from 'rxjs';
import { switchMap, mergeMap, tap, catchError } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        public account: AccountService, 
        public client: ClientService, 
        public router: Router,
        private _snackBar: MatSnackBar
        ) { }
    canActivate(): Observable<boolean> | boolean {
        //TODO: refactor this snippet: revise isAuthenticated function, remove login component
        // return this.account.isAuthenticatedNew().pipe(
        //     tap((accountIsAuthenticated: boolean) => {
        //         if (!accountIsAuthenticated) {
        //             this._openNotification("Account Token Expired! Login again.", "Dismiss");
        //             throw 'account token expired';
        //         }
        //         return of(true);
        //     }),
        //     switchMap(() => {
        //         return this.client.isLoaded().pipe(
        //             tap((clientIsLoaded: boolean) => {
        //                 if (!clientIsLoaded) {
        //                     this._openNotification("Client Token Expired! Login again.", "Dismiss");
        //                     throw 'client token expired';
        //                 }
        //                 return of(true);                        
        //             })
        //         );
        //     }),
        //     catchError((error: any) => {
        //         console.log("redirecting to login");

        //         //TODO implement a general logout functionality
        //         this.router.navigate(['./login-component']);
        //         return of(false);
        //     })

        // );
        return true;
    }

    _openNotification(message: string, action: string, timespan: number = 50000) {
        this._snackBar.open(message, action);
    }
}
