<div class="login">
    <div class="credentials">
        <div class="loader">
            <mat-spinner color="primary" diameter="35"></mat-spinner><br />
        </div>
        <div class="description">
            <h1>Verifying credentials...</h1>
        </div>
    </div>
</div>
<!-- <mat-card> -->
<!-- <img mat-card-image src="/assets/img/portal_logo.svg" alt="Avangarde Portal"> -->
<!-- <mat-card-title></mat-card-title>
    <mat-card-content> -->
<!-- <form [formGroup]="form" (ngSubmit)="submit()">
      <p>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input type="email" matInput id="username" formControlName="username" [errorStateMatcher]="matcher"
                 placeholder="Ex. john.doe@avangarde-software.com">
          <mat-error *ngIf="form.get('username')?.hasError('email') && !form.get('username')?.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="form.get('username')?.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="outline">
          <mat-label>Enter your password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="form.get('password')?.hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </p>

      <p *ngIf="error" class="error">
        {{ error }}
      </p>

      <div class="button">
        <button *ngIf="!isLoading" type="submit" mat-raised-button>
            <mat-icon>login</mat-icon> Enter
        </button>
        <button *ngIf="isLoading" mat-flat-button>
            <mat-spinner color="primary" diameter="35"></mat-spinner>
        </button>
      </div>

    </form> -->
<!-- </mat-card-content>
    <mat-card-actions>

    </mat-card-actions>
</mat-card> -->
