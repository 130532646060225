import { Input, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AccountService } from '../services/account.service';
import { environment } from "src/environments/environment";

import { Router } from '@angular/router';
import { catchError, of, pipe, throwError } from 'rxjs';
import { ClientService } from '../services/client.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'portal-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    hide = true;
    durationInSeconds = 5;
    form: FormGroup = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
    });
    matcher = new MyErrorStateMatcher();
    isLoading = false;
    @Input() error: string | null;
    @Output() submitEM = new EventEmitter();
    constructor(
        private account: AccountService,
        private client: ClientService,
        private router: Router,
        private _snackBar: MatSnackBar
    ) {
        console.log("Initializing component login");
    }
    ngOnInit(): void {
        console.log("which environment did i get?", environment);
        window.location.href = `${environment.API_LOGIN_REDIRECT_URL}?redirect_uri=${environment.APP_BASE_URL}${environment.APP_DASHBOARD_URL}`;
    }

    openSnackBar() {
        this._snackBar.open("Message archived", 'Undo', {
            duration: this.durationInSeconds * 1000,
        });
    }
    submit() {
        // window.location.href = `${environment.API_LOGIN_REDIRECT_URL}?redirect_uri=${environment.APP_DASHBOARD_URL}`;
    }
}
