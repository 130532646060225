<!-- Toolbar -->
<div class="toolbar" role="banner">
    <!-- <svg width="200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><defs><style>.cls-1{fill:#1e2337;}.cls-2,.cls-3{font-size:360px;font-family:ArialMT, Arial;}.cls-2,.cls-3,.cls-4,.cls-6{fill:#fff;}.cls-2{letter-spacing:-0.02em;}.cls-3{letter-spacing:0.03em;}.cls-4,.cls-6{font-size:55px;}.cls-4{font-family:Montserrat-Regular, Montserrat;letter-spacing:-0.01em;}.cls-5{letter-spacing:0em;}.cls-6{font-family:Montserrat-SemiBold, Montserrat;font-weight:600;}.cls-7{letter-spacing:-0.04em;}.cls-8{fill:#a01d22;}.cls-9{fill:#c22026;}</style></defs><g id="BG"><rect class="cls-1" x="-57" y="-57" width="1138" height="1138"/></g><g id="Portal"><text class="cls-2" transform="translate(0.49 622.17)">P</text><text class="cls-3" transform="translate(482.65 622.17)">rtal</text><text class="cls-4" transform="translate(557.46 695.18)">b<tspan class="cls-5" x="36.46" y="0">y </tspan></text><text class="cls-6" transform="translate(637.46 695.18)"><tspan class="cls-7">AV</tspan><tspan x="77.22" y="0">ANGARDE</tspan></text><path class="cls-8" d="M404.76,612.43c-39.58,0-71.66-48.08-71.66-107.38s32.08-107.38,71.66-107.38c25.91,0,48.6,20.6,61.19,51.47-13.73-40.65-42.2-68.59-75.09-68.59-46.31,0-83.85,55.36-83.85,123.64s37.54,123.64,83.85,123.64c30,0,56.3-23.23,71.12-58.14C448.9,595.65,428.14,612.43,404.76,612.43Z"/><path class="cls-9" d="M414,404.86c24.42,19.51,40.74,54.78,40.74,95,0,47.46-22.67,88-54.57,103.92,38.51-1.58,69.39-46.06,69.39-100.71C469.57,455.18,445.82,415.07,414,404.86Z"/><path class="cls-9" d="M284.2,504.19c0-75.36,37.29-137.75,85.9-148.52a69.15,69.15,0,0,0-15-1.64c-55.71,0-100.87,67.23-100.87,150.16s45.16,150.16,100.87,150.16a69.15,69.15,0,0,0,15-1.64C321.49,641.94,284.2,579.55,284.2,504.19Z"/></g></svg> -->
    <svg width="300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 400">
        <defs>
            <style>
                .cls-1 {
                    fill: #1e2337;
                }

                .cls-2 {
                    fill: #fff;
                }

                .cls-3 {
                    fill: #a01d22;
                }

                .cls-4 {
                    fill: #c22026;
                }
            </style>
        </defs>
        <g id="Portal">
            <rect class="cls-1" width="1024" height="400" />
            <path class="cls-2"
                d="M28.26,622.17V364.48h97.21q25.67,0,39.2,2.46,19,3.17,31.81,12t20.66,24.87A79,79,0,0,1,225,439q0,32.87-20.92,55.63t-75.58,22.77H62.36V622.17ZM62.36,487H129q33.06,0,46.94-12.31t13.89-34.63q0-16.17-8.18-27.68a38.21,38.21,0,0,0-21.53-15.21q-8.62-2.28-31.82-2.28H62.36Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M506,622.17V435.49H534.5v28.3q10.9-19.86,20.13-26.19a35.12,35.12,0,0,1,20.3-6.33q16,0,32.52,10.2l-10.9,29.35Q585,464,573.35,464a30.14,30.14,0,0,0-18.63,6.24q-8.26,6.24-11.78,17.31a123.46,123.46,0,0,0-5.27,36.92v97.73Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M707.58,593.87l4.57,28a117.6,117.6,0,0,1-23.9,2.81q-17.24,0-26.72-5.45t-13.36-14.32Q644.3,596,644.3,567.5V460.1H621.1V435.49h23.2V389.26l31.47-19v65.21h31.81V460.1H675.77V569.26q0,13.54,1.67,17.4a13.2,13.2,0,0,0,5.45,6.16q3.78,2.28,10.81,2.28A102.93,102.93,0,0,0,707.58,593.87Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M872.58,599.14q-17.58,15-33.84,21.1a97.8,97.8,0,0,1-34.89,6.15q-30.76,0-47.29-15T740,573a51.09,51.09,0,0,1,6.24-25.05,51.62,51.62,0,0,1,16.35-18.19,76.64,76.64,0,0,1,22.76-10.37q9.32-2.46,28.13-4.75,38.31-4.56,56.42-10.9.18-6.49.18-8.26,0-19.33-9-27.24-12.12-10.72-36-10.73-22.33,0-33,7.83T776.43,493l-30.94-4.22q4.21-19.86,13.89-32.08t27.94-18.81q18.28-6.58,42.37-6.59,23.89,0,38.85,5.63t22,14.15q7,8.52,9.84,21.53,1.59,8.09,1.58,29.18V544q0,44.12,2,55.81a70.34,70.34,0,0,0,8,22.41h-33A66.72,66.72,0,0,1,872.58,599.14Zm-2.64-70.66q-17.22,7-51.68,11.95-19.51,2.82-27.6,6.33A27.56,27.56,0,0,0,778.18,557a28,28,0,0,0,5.19,36.13q9.57,8.43,28,8.43,18.27,0,32.52-8a49.22,49.22,0,0,0,20.91-21.89q5.1-10.71,5.1-31.64Z"
                transform="translate(0 -312)" />
            <path class="cls-2" d="M962.51,622.17V364.48h31.64V622.17Z" transform="translate(0 -312)" />
            <path class="cls-2"
                d="M592.21,680.72c0,8.74-6.15,14.74-14.4,14.74a12.53,12.53,0,0,1-11.17-6v5.72H562.9v-40.8h3.91v17.37a12.65,12.65,0,0,1,11-5.71C586.06,666,592.21,672,592.21,680.72Zm-3.9,0c0-6.77-4.67-11.27-10.78-11.27s-10.78,4.5-10.78,11.27S571.37,692,577.53,692,588.31,687.48,588.31,680.72Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M623.67,666.26l-14.46,32.39c-2.42,5.66-5.5,7.47-9.62,7.47a9.88,9.88,0,0,1-7-2.63l1.81-2.92a7.22,7.22,0,0,0,5.28,2.2c2.59,0,4.35-1.21,6-4.84l1.26-2.8L594,666.26h4.07l10.89,24.58,10.89-24.58Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M667.6,686.27H648.35l-3.8,8.91h-7.37l17.33-38.49h7l17.37,38.49h-7.47Zm-2.37-5.61L658,663.84l-7.2,16.82Z"
                transform="translate(0 -312)" />
            <path class="cls-2" d="M717,656.69l-16.77,38.49h-7l-16.82-38.49h7.75l12.81,29.69,12.93-29.69Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M744.82,686.27H725.57l-3.8,8.91h-7.36l17.32-38.49h7l17.38,38.49h-7.48Zm-2.37-5.61-7.26-16.82L728,680.66Z"
                transform="translate(0 -312)" />
            <path class="cls-2" d="M795.25,656.69v38.49h-5.88l-21.23-26.06v26.06H761V656.69h5.89l21.23,26.06V656.69Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M831.88,675.49h6.76v15.35a24.37,24.37,0,0,1-15.06,4.89c-11.94,0-20.74-8.3-20.74-19.8s8.8-19.79,20.85-19.79c6.43,0,11.82,2.14,15.45,6.27l-4.51,4.4a14.26,14.26,0,0,0-10.61-4.4c-8.2,0-14,5.55-14,13.52,0,7.81,5.77,13.53,13.91,13.53a15.54,15.54,0,0,0,7.92-2Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M873,686.27H853.77L850,695.18h-7.37l17.32-38.49h7l17.38,38.49h-7.48Zm-2.36-5.61-7.26-16.82-7.21,16.82Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M914.43,695.18l-7.86-11.27a14.74,14.74,0,0,1-1.49.05H896.4v11.22h-7.15V656.69h15.83c10.12,0,16.39,5.17,16.39,13.69a12.3,12.3,0,0,1-8.19,12.15l8.85,12.65Zm-9.67-32.44H896.4v15.34h8.36c6.26,0,9.51-2.86,9.51-7.7S911,662.74,904.76,662.74Z"
                transform="translate(0 -312)" />
            <path class="cls-2"
                d="M929.45,656.69h16.83c12.48,0,21,7.7,21,19.24s-8.53,19.25-21,19.25H929.45ZM946,689.13c8.58,0,14.13-5.22,14.13-13.2s-5.55-13.19-14.13-13.19H936.6v26.39Z"
                transform="translate(0 -312)" />
            <path class="cls-2" d="M1003.75,689.19v6H974.88V656.69H1003v6H982v10h18.59v5.88H982v10.62Z"
                transform="translate(0 -312)" />
            <path class="cls-3"
                d="M404.76,612.43c-39.58,0-71.66-48.08-71.66-107.38s32.08-107.38,71.66-107.38c25.91,0,48.6,20.6,61.19,51.47-13.73-40.65-42.2-68.59-75.09-68.59-46.31,0-83.85,55.36-83.85,123.64s37.54,123.64,83.85,123.64c30,0,56.3-23.23,71.12-58.14C448.9,595.65,428.14,612.43,404.76,612.43Z"
                transform="translate(0 -312)" />
            <path class="cls-4"
                d="M414,404.86c24.42,19.51,40.74,54.78,40.74,95,0,47.46-22.67,88-54.57,103.92,38.51-1.58,69.39-46.06,69.39-100.71C469.57,455.18,445.82,415.07,414,404.86Z"
                transform="translate(0 -312)" />
            <path class="cls-4"
                d="M284.2,504.19c0-75.36,37.29-137.75,85.9-148.52a69.15,69.15,0,0,0-15-1.64c-55.71,0-100.87,67.23-100.87,150.16s45.16,150.16,100.87,150.16a69.15,69.15,0,0,0,15-1.64C321.49,641.94,284.2,579.55,284.2,504.19Z"
                transform="translate(0 -312)" />
        </g>
    </svg>
    <div class="spacer"></div>
    <div *ngIf="isLoading">
        <mat-spinner class="loader" diameter="35"></mat-spinner>
    </div>

    <div *ngIf="!isLoading && profileData" class="profile-card">
        <div class="profile-item-role">
            {{profileData?.roleNames.length ? profileData?.roleNames.join(","): "Member"}}&nbsp;<span class="member-not-activated" *ngIf="!isLoading && accountIsActivated === 'false'">(Not activated)</span>&nbsp;<mat-icon
                style="font-size: 2em;color:#c22026">vpn_key</mat-icon>
        </div>

        <div class="profile-item-user">
            <span>{{profileData?.firstName}} {{profileData?.lastName}}</span>
        </div>
        <!-- <mat-divider class="section-divider"></mat-divider> -->
        <div class="profile-item-action">
            <button mat-button (click)="logout()" color="warn">
                <mat-icon>power_settings_new</mat-icon>Logout
            </button>
        </div>
    </div>
    <!-- <a aria-label="Angular on twitter" target="_blank" rel="noopener" href="https://twitter.com/angular" title="Twitter">
      <svg id="twitter-logo" height="24" data-name="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <rect width="400" height="400" fill="none"/>
        <path d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23" fill="#fff"/>
      </svg>
    </a>
    <a aria-label="Angular on YouTube" target="_blank" rel="noopener" href="https://youtube.com/angular" title="YouTube">
      <svg id="youtube-logo" height="24" width="24" data-name="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M21.58 7.19c-.23-.86-.91-1.54-1.77-1.77C18.25 5 12 5 12 5s-6.25 0-7.81.42c-.86.23-1.54.91-1.77 1.77C2 8.75 2 12 2 12s0 3.25.42 4.81c.23.86.91 1.54 1.77 1.77C5.75 19 12 19 12 19s6.25 0 7.81-.42c.86-.23 1.54-.91 1.77-1.77C22 15.25 22 12 22 12s0-3.25-.42-4.81zM10 15V9l5.2 3-5.2 3z"/>
      </svg>
    </a> -->


</div>
<div class="content" role="main">
    <router-outlet></router-outlet>
</div>
