import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material.module';
import { ClientService } from './services/client.service';
import { AccountService } from './services/account.service';
import { SettingsDialog, DashboardComponent } from './dashboard/dashboard.component';

import { AuthGuardService } from './services/auth-guard.service';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { TokenService } from "./services/token.service";

import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { environment } from 'src/environments/environment';
export function jwtOptionsFactory(tokenService: TokenService) {
    return {
        tokenGetter: () => tokenService.token,
        whitelistedDomains: [environment.APP_BASE_URL, environment.APP_BFF_URL]
    };
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        SettingsDialog
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        HttpClientModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [TokenService]
            }
        })
    ],
    providers: [
        ClientService,
        AccountService,
        AuthGuardService,
        AuthInterceptor,
        TokenService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 2500, panelClass: "red-snackbar" }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
