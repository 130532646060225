import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class TokenService {
  private _token: string = "some_token";

  public get token(): string {
    return this._token;
  }
}
