<div class="settings-container">
    <mat-card class="reset-password-container">
        <mat-card-header>
            <mat-icon>edit</mat-icon>
            <mat-card-title class="mat-card-title-custom"> Change your Password</mat-card-title>
        </mat-card-header>
        <!-- <mat-divider class="section-divider"></mat-divider> -->
        <mat-card-content>
            <form [formGroup]="settingsForm" (ngSubmit)="onUpdatePassword()">
                <div>
                    <mat-form-field class="password-field">
                        <mat-label for="oldPassword">Old Password</mat-label>
                        <input id="old-password" matInput type="text" formControlName="oldPassword">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="password-field">
                        <mat-label for="password">New Password</mat-label>
                        <input id="password" matInput type="text" formControlName="password">
                        <mat-hint>
                            Must contain numbers [0-9], letters [a-z], [A-Z] and special characters (@#$...)</mat-hint>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="password-field">
                        <mat-label for="confirmPassword">Confirm Password</mat-label>
                        <input id="confirm-password" matInput type="text" formControlName="confirmPassword">
                    </mat-form-field>
                </div>
                <div class="update-password-actions">
                    <button *ngIf="!isLoading" type="submit" mat-button [disabled]="!settingsForm.valid || isLoading">
                        <mat-icon>edit</mat-icon> Update password
                    </button>
                    <div class="loader" *ngIf="isLoading">
                        <mat-spinner color="secondary" diameter="15"></mat-spinner>
                        <h1 class="loader-desc"></h1>
                    </div>
                    <button *ngIf="!isLoading" mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
    <!-- <div class="reset-password-container">
        <h1>
            <mat-icon>edit</mat-icon>Change your Password
        </h1>
        <form [formGroup]="settingsForm" (ngSubmit)="onUpdatePassword()">
            <div>
                <mat-form-field class="password-field">
                    <mat-label for="oldPassword">Old Password</mat-label>
                    <input id="old-password" matInput type="text" formControlName="oldPassword">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="password-field">
                    <mat-label for="password">New Password</mat-label>
                    <input id="password" matInput type="text" formControlName="password">
                    <mat-hint>
                        Must contain numbers [0-9], letters [a-z], [A-Z] and special characters (@#$...)</mat-hint>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="password-field">
                    <mat-label for="confirmPassword">Confirm Password</mat-label>
                    <input id="confirm-password" matInput type="text" formControlName="confirmPassword">
                </mat-form-field>
            </div>
            <div class="update-password-actions">
                <button *ngIf="!isLoading" type="submit" mat-button [disabled]="!settingsForm.valid || isLoading">
                    <mat-icon>edit</mat-icon> Update password
                </button>
                <div class="loader" *ngIf="isLoading">
                    <mat-spinner color="secondary" diameter="15"></mat-spinner>
                    <h1 class="loader-desc"></h1>
                </div>
                <button *ngIf="!isLoading" mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
            </div>
        </form>
    </div> -->
    <!-- <mat-card class="misc-settings-container">
        <mat-card-header>
            <mat-icon>account_box</mat-icon>
            <mat-card-title class="mat-card-title-custom"> Other Settings</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <i>No settings currently available.</i>
        </mat-card-content>
    </mat-card> -->
    <!-- <div class="misc-settings-container">
        <h1>
            <mat-icon>account_box</mat-icon>Other Settings
        </h1>
        <i>No settings currently available.</i>
    </div> -->
</div>
