import { Component, OnInit } from '@angular/core';
import { AccountService } from './services/account.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = "Role & Permissions";
    isLoading = true;
    accountIsActivated = 'true';
    constructor(private account: AccountService) { }
    profileData: any;
    ngOnInit() {
        this.account.accountEvent.subscribe((event: any) => {
            if (event.name === "logout") {

            }
            if (event.name === "actionUpdateViewProfile") {
                setTimeout(() => {
                    this.isLoading = false;
                }, 0);
                this.profileData = event.data;
                this.profileData.roleNames = [];
                this.profileData.roles.forEach((roleObj: any) => {
                    console.log("foreach", roleObj);
                    this.profileData.roleNames.push(roleObj.name);
                });
                console.log("APPCOMPONENT RECEIVING:", event.data);
                this.profileData.decoratedEmail = this.profileData.email ? this.profileData.email.split("@")[0] : "Admin";


            }
            if (event.name === "viewAccountIsActivated") {
                this.accountIsActivated = event.data;
            }

        });
    }

    logout() {
        this.account.logout().subscribe(() => {});
    }

}
