import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { mergeMap, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AccountService } from './account.service';
import { ClientService } from './client.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        // private notifier: NotifierService,
        private jwtHelper: JwtHelperService,
        private account: AccountService,
        private client: ClientService,
        private router: Router,
        private _snackBar: MatSnackBar
        // private configurationService: ConfigurationService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log("ajung in interceptor");
        //TODO: exception endpoints. these should be moved somewhere else
        let exceptionGroupUrl: any[] = [
            "api/auth/token/client",
            "api/auth/token",
            "api/auth/v1/token",
        ];
        let credentialGroupUrl: any[] = [
            "auth/healthcheck",
            "auth/client",
            "auth/apis",
            "auth/account",
            "auth/cookie",
            "auth/account/load",
            "/auth/api/add",
            "auth/api/account/password",
            "auth/logout"
        ];

        let accountGroupUrl: any[] = [
            "api/account/load"
        ];
        let clientGroupUrl: any[] = [
            "api/api-service"
        ];
        console.log("intercepting url: ", req.url);
        const isAnExceptionGroup = exceptionGroupUrl.filter((partialUrl) => { return req.url.includes(partialUrl); }).pop();
        // const isAnAccountGroup = accountGroupUrl.filter((partialUrl) => { return req.url.includes(partialUrl); }).pop();
        // const isAClientGroup = clientGroupUrl.filter((partialUrl) => { return req.url.includes(partialUrl); }).pop();
        const isACredentialGroup = credentialGroupUrl.filter((partialUrl) => { return req.url.includes(partialUrl); }).pop();
        console.log("is a credential group", isACredentialGroup);
        // const isLogoutUrl = req.url.includes("auth/logout");
        const isLogoutUrl = false;

        // if (!isAnExceptionGroup && !isACredentialGroup) {
        //     let tokenRequest$;
        //     if (isAnAccountGroup) {
        //         tokenRequest$ = this.account.getAccToken();
        //     } else {
        //         tokenRequest$ = this.client.getCliToken();
        //     }
        //     return tokenRequest$.pipe(mergeMap((token: any) => {
        //         if (token) {
        //             if (this.jwtHelper.isTokenExpired(token)) {
        //                 this.account.logout();
        //                 this.client.logout();
        //             } else {
        //                 const headers = new HttpHeaders()
        //                     .set('content-type', 'application/json')
        //                     .append("Authorization", "Bearer " + token);
        //                 console.log("setting headers to authorization", headers);
        //                 req = req.clone({
        //                     headers: headers
        //                     // withCredentials: true
        //                 });
        //             }
        //         }

        //         return next.handle(req)
        //             .pipe(catchError((error, caught) => {
        //                 // intercept the response error and displace it to the console
        //                 const dataToEmit = {
        //                     error: error.error,
        //                     readmore: {
        //                         name: error.name,
        //                         message: error.message,
        //                         status: error.status,
        //                         // timestamp: moment().format('x'),
        //                         payload: {
        //                             body: req.body,
        //                             method: req.method,
        //                             url: req.url
        //                         }
        //                     }
        //                 };
        //                 console.log("emitting error: ", dataToEmit);
        //                 this._snackBar.open(dataToEmit.readmore.message, 'Dismiss');
        //                 throw error;
        //             }));
        //     }));
        // }
        console.log("isLogout url:", isLogoutUrl);
        console.log("isACredential url:", isACredentialGroup);
        if (!isLogoutUrl) {
            let headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
            if (isACredentialGroup) {
                console.log("appending withCredentials to url");
                req = req.clone({
                    headers: headers,
                    withCredentials: true
                });

            } else {
                console.log("not A CREDENTIAL URL!!");
                req = req.clone({
                    headers: headers
                });

            }
        } else {
            // req = req.clone({
            //     headers: new HttpHeaders()
            //         .append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9')
            //         .append('Access-Control-Allow-Origin', '*')
            // });
        }

        return next.handle(req)
            .pipe(catchError((error, caught) => {
                let errorMessage = error?.error?.message ? error?.error?.message : error.message;

                if (!error.status) {
                    this._snackBar.open("Connection to backend server is refused. Please check if server is operational!", 'Dismiss', { duration: 0 });
                } else {
                    this._snackBar.open(errorMessage, 'Dismiss');
                }
                throw error;

            }));

    }

    _openNotification(message: string, action: string, timespan: number = 50000) {
        this._snackBar.open(message, action);
    }

}
