import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';
import { switchMap, mergeMap } from 'rxjs';
import { ClientService } from '../services/client.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY } from 'rxjs';
import { BehaviorSubject, Observable, Subscription, Subject, of, forkJoin, pipe, map, catchError, throwError, tap } from "rxjs";

@Component({
    selector: 'settings-dialog',
    templateUrl: 'settings-dialog.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsDialog {
    settingsForm: FormGroup;
    isLoading: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<SettingsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private account: AccountService,
        private _snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        let passwordPattern = `(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+{}\\[\\]:;,.<>\/?-])(?=\\S+$).{6,32}`;
        let oldPasswordPattern = `(?=.*[0-9]).{6,32}`;
        this.settingsForm = this.fb.group({
            oldPassword: ['', [Validators.required, Validators.pattern(oldPasswordPattern)]],
            password: ['', [Validators.required, Validators.pattern(passwordPattern)]],
            confirmPassword: ['', [Validators.required, Validators.pattern(passwordPattern)]]
        });
    }

    onCloseDialog(): void {
        console.log("Dialog closing...");
        this.dialogRef.close();
    }

    onUpdatePassword() {
        console.log(this.settingsForm.value);
        this.isLoading = true;
        this.account.updatePassword(this.settingsForm.value.oldPassword, this.settingsForm.value.password, this.settingsForm.value.confirmPassword).subscribe({
            next: () => {
                this.isLoading = false;
                this.dialogRef.close();
                this._snackBar.open("Your password has been successfully updated!", 'Dismiss', {
                    panelClass: ['special-snackbar'],
                    duration: 3000
                });
            },
            error: () => {
                this.isLoading = false;
            }
        });
    }
}


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    credentials: any = {};
    isLoading = true;
    authorizationCode = null;
    authorizationType = null;
    clientTokenAccess = "";
    accountIsActive = 'true';
    credentialsAsQParams: string = "";
    apiList: any = [];
    settingsDialogRef: any;
    apiStructure: any = {
        company: { 'wiki': false, 'regulations': false, 'events': false },
        members: { 'checkin': false, 'worklogs': true, 'career': false, 'spaces': false, 'vt': false },
        hq: { 'hr': false, 'configurator': false, 'pmanager': false }
    };
    apiStructureVisibility = {};
    constructor(private account: AccountService, private client: ClientService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) { }
    longText = "lorem ipsum dolor sit amet";
    ngOnInit(): void {
        console.log("ORIGIN", window.location.origin, window.location.pathname);
        this.isLoading = true;
        this.route.queryParams
            .subscribe(params => {
                console.log(params); // { orderby: "price" }
                this.authorizationCode = params["authorisation_code"];
                this.clientTokenAccess = params["client_token"];
                this.authorizationType = params["type"];
                // this.account.testHttpsCall().subscribe((response) => {
                //     console.log("call successfully made", response);
                // });
                if (!this.authorizationType) {
                    this.account.reqAccTokenByAuthCodeNew(params["authorisation_code"]).subscribe((response) => {
                        this._performAuthenticatedActionsNew(true);
                    });

                } else {
                    this.client.reqCliTokenByAuthCode(params["authorisation_code"]).subscribe(() => {
                        this._performFirstVisitActions(this.authorizationCode);
                    });

                }
            });
    }

    _performFirstVisitActions(authorizationCode: any) {
        console.log(this.clientTokenAccess);

        this.account.accountEvent.next({
            name: "actionUpdateViewProfile", data: {
                roles: ["Admin"],
                firstName: "First",
                lastName: "Visit"
            }
        });
        let authorizationCodeQuery = `?authorisation_code=${this.authorizationCode}&type=client`;
        this.client.listPermittedApisNew([], "admin", authorizationCodeQuery).subscribe((permittedApis: any) => {
            console.log('listing permitted apis', permittedApis);
            this.apiList = permittedApis;
            this._updateApiStructure();
            if (!this.apiList.length) {
                this.client.createPManagerAPI().subscribe(() => {
                    window.location.reload();
                    this.isLoading = false;
                });
            } else {
                this.isLoading = false;
            }

        });
    }

    _performAuthenticatedActionsNew(isAuthenticated: boolean): void {
        if (!isAuthenticated) {
            console.log("User is not allowed here!");
            return;
        }

        this.account.getAccProfileNew()
            .pipe(
                tap((profileData: any) => console.log("Profile data is", profileData)),
                switchMap((profileData: any) => {
                    const { active, roles, accountPermissions } = profileData;
                    const isAdmin = roles.some((role: any) => role.name.toLowerCase() === "admin");
                    return this.client.reqCliTokenBff().pipe(
                        tap(() => this.isLoading = true),
                        switchMap(() => this.client.listPermittedApisNew(accountPermissions, isAdmin ? "admin" : "", `?authorisation_code=${this.authorizationCode}`)),
                        tap((permittedApis: any) => {
                            // console.log("Listing permitted APIs", permittedApis);
                            this.apiList = permittedApis;

                            //display existing APIs
                            // console.log("Company", this.verifyApiCategory('company'));
                            // console.log("Members", this.verifyApiCategory('members'));
                            // console.log("HQ", this.verifyApiCategory('hq'));
                            this._updateApiStructure();
                            this.isLoading = false;
                        }),
                        catchError((e: any) => {
                            this.isLoading = false;
                            console.log("Received an error in request cli token", e);
                            return EMPTY;
                        }),
                        map(() => profileData)
                    );
                })
            )
            .subscribe({
                next: (profileData: any) => {
                    const { active } = profileData;
                    if (active) {
                        this.account.accountEvent.next({ name: "viewAccountIsActivated", data: active });
                    }
                    this.account.accountEvent.next({ name: "actionUpdateViewProfile", data: profileData });
                },
                error: (e: any) => console.log("Received an error in getAccProfileNew", e)
            });
    }
    _updateApiStructure() {
        this.apiList.forEach((apiItem: any) => {
            let category = this._getCategoryByApi(apiItem.name);
            if (category) {
                this.apiStructure[category][apiItem.name] = true;
            }
        });
        console.log("updated API structure", this.apiStructure);
    }

    _getCategoryByApi(apiName: string) {
        for (const [category, apiStructureItem] of Object.entries(this.apiStructure)) {
            for (const [sectionName, isVisible] of Object.entries(apiStructureItem as any)) {
                if (apiName === sectionName) return category;
            }
        }
        return false;
    }

    getPermittedApiByName(apiName: string) {
        return this.apiList.filter((apiItem: any) => {
            return apiItem.name === apiName;
        })[0];
    }

    verifyApiCategory(category: string) {
        return Object.entries(Object.fromEntries(Object.entries(this.apiStructure[category]).filter(([key]) => this.apiStructure[category][key] == true))).length;
    }
    openSettings() {
        console.log("open settings");
        this.settingsDialogRef = this.dialog.open(SettingsDialog);
    }
}
