import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, mergeMap, Observable, Subscription, Subject, of, forkJoin, pipe, switchMap, map, catchError, throwError, tap } from "rxjs";

import jwtDecode from 'jwt-decode';
import { StorageMap } from '@ngx-pwa/local-storage';
// import { JwtHelperService } from '@auth0/angular-jwt';
import { ClientService } from "./client.service";

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    clientData: any = {};
    accountEvent = new Subject();
    accToken: null;
    constructor(private http: HttpClient, private storage: StorageMap, private client: ClientService) {
        if (!environment.PERMISSION_API_BASE_URL) {
            throw "Environment variables not properly loaded!";
        }
        this.clientData["permissionApiBaseUrl"] = environment.PERMISSION_API_BASE_URL;
        console.log("loaded environment", environment);

    }

    // reqAccToken(email: string, password: string): any {
    //     const urlString = `${this.clientData["permissionApiBaseUrl"]}api/auth/token`;
    //     const http$ = this.http.post(urlString, {
    //         email: email,
    //         password: password,
    //         withCredentials: true
    //     });
    //     console.log("what is http?", urlString, http$);
    //     return http$.pipe(
    //         catchError(err => {
    //             console.log('Handling error locally and rethrowing it...', err);
    //             throw of(err);
    //         }),
    //         switchMap((response: any) => {
    //             const profileData = jwtDecode(response.accessToken);
    //             if (profileData) {
    //                 return this.storage.set('accToken', response.accessToken).pipe(map(() => {
    //                     this.accToken = response.accessToken;
    //                     const profileData = jwtDecode(response.accessToken);
    //                     return profileData;
    //                 }));
    //             } else {
    //                 return of([]);
    //             }
    //         }));
    // }
    testHttpsCall() {
        const urlString = `${environment.APP_BFF_URL}/auth/healthcheck`;
        const http$ = this.http.get(urlString);
        return http$.pipe(
            catchError((err, caught) => {
                console.log('Handling error locally and rethrowing it...')
                console.log(err); 
                console.log(caught);
                if (err.status) {
                    // window.location.href = `${environment.API_LOGIN_REDIRECT_URL}?redirect_uri=${environment.APP_BASE_URL}${environment.APP_DASHBOARD_URL}`;

                } else {
                    console.error("Missing server response! Please check if backend server is available");
                }

                throw of(err);
            }),
            switchMap((response: any) => {
                return of(response);
            }));        
    }

    reqAccTokenByAuthCodeNew(authorizationCode: string) {
        const urlString = `${environment.APP_BFF_URL}/auth/account`;
        const http$ = this.http.post(urlString, {
            authorisationCode: authorizationCode
        });
        return http$.pipe(
            catchError(err => {
                console.log('Handling error locally and rethrowing it...', err);
                if (err.status) {
                    window.location.href = `${environment.API_LOGIN_REDIRECT_URL}?redirect_uri=${environment.APP_BASE_URL}${environment.APP_DASHBOARD_URL}`;

                } else {
                    console.error("Missing server response! Please check if backend server is available");
                }

                throw of(err);
            }),
            switchMap((response: any) => {
                return of(response);
            }));
    }

    reqAccTokenCookie() {
        const urlString = `${environment.APP_BFF_URL}/auth/cookie`;
        let http$ = this.http.post(urlString, {});
        return http$.pipe(
            catchError(err => {
                console.log('Handling error locally and rethrowing it...', err);
                throw of(err);
            }),
            switchMap((response: any) => {
                return of(response);
            }));
    }
    isAdmin(profileData: any): Boolean {
        return profileData
    }

    getAccToken(): Observable<any> {
        return this.storage.get('accToken').pipe(mergeMap((token: any) => {
            return new BehaviorSubject<any>(token).asObservable();
        }));
    }

    // getDecodedAccToken(): Observable<any> {
    //     return this.storage.get('accToken').pipe(mergeMap((token: any) => {
    //         return new BehaviorSubject<any>(this.jwtHelper.decodeToken(token)).asObservable();
    //     }));
    // }

    getAccProfileNew(): Observable<any> {
        return this.storage.get('profile').pipe(
            switchMap((profile: any) => {
                console.log("does profile have anything?", profile);
                if (!profile) {
                    console.log("NO, requesting account load");
                    return this.reqAccLoadNew();
                } else {
                    return of(profile);
                }
        }));
    }

    reqAccLoadNew(): Observable<any> {
        const urlString = `${environment.APP_BFF_URL}/auth/account/load`;
        console.log("url string is", urlString);
        let params = {};
        let http$ = this.http.get(urlString, { params: params});
        return http$.pipe(
            catchError(err => {
                console.log('Handling error locally and rethrowing it...', err);
                throw of(err);
            }),
            switchMap((response: any) => {
                console.log("account load response:", response);
                // return this.storage.set('profile', response).pipe(map(() => {
                //     return response;
                // }));
                return of(response);
            })
        );
    }

    // isAuthenticated(): Observable<boolean> {
    //     return this.storage.get('accToken').pipe(mergeMap((token: any) => {
    //         const _isAuthenticated: boolean = (token && !this.jwtHelper.isTokenExpired(token));
    //         return new BehaviorSubject<boolean>(_isAuthenticated).asObservable();
    //     }));
    // }

    isAuthenticatedNew(authorisationCode: any): Observable<boolean> {
        return new BehaviorSubject<boolean>(authorisationCode).asObservable();
    }


    getClientData() {
        return this.clientData;
    }

    listPermittedApis(apiList: Array<object>) {

    }

    updatePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
        const urlString = `${environment.APP_BFF_URL}/auth/api/account/password`;
        const http$ = this.http.put(urlString, {
            oldPassword: oldPassword,
            password: newPassword,
            confirmPassword: confirmPassword
        });
        return http$.pipe(
            catchError(err => {
                console.log('Handling error locally and rethrowing it...', err);
                throw of(err);
            }),
            switchMap((response: any) => {
                console.log("account load response:", response);
                return of(response);
            })
        );
    }

    logout() {
        const urlString = `${environment.APP_BFF_URL}/auth/logout`;
        console.log(urlString);
        const http$ = this.http.post(urlString, {});
        return http$.pipe(
            catchError(err => {
                console.log('Handling error locally and rethrowing it...', err);
                throw of(err);
            }),
            switchMap((response: any) => {
                return this.storage.clear().pipe(map(() => {
                    window.location.href = `${environment.API_LOGOUT_REDIRECT_URL}?redirect_uri=${environment.APP_BASE_URL}${environment.APP_DASHBOARD_URL}`;
                    return of(true);
                }));
            })
        );        
    }
}
